:root {
  --grid-window-content-padding: 0px;
  --primary-color: #0078d4;
  --bs-table-bg: #cce4f6;
}

body {
  background-color: #edf1f5 !important;
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI;
  /* font-size: 0.8333333333rem !important; */
  font-size: 14px !important;
  line-height: 20px !important;
}

.dropdown-item-padding {
  padding: 0.25rem 1rem;
}

.form-switch-right-text {
  padding-left: 0px !important;
  margin-left: 4px !important;
}

.dropdown-menu,
.form-control {
  font-size: inherit !important;
}

.menu-dropdown {
  font-weight: bold;
}

.menu-item-spacing-right {
  padding-right: 5px;
}

.menu-item-spacing-left {
  padding-left: 5px;
}

.navbar-brand {
  margin-right: 5px !important;
}

.navbar-nav-fa {
  cursor: pointer;
}

.slider-mark {
  padding-top: 4px;
}

.slider-mark-hide {
  display: none;
}

.slider-mark-left {
  padding-right: 6px;
}

.slider-mark-right {
  padding-left: 6px;
}

.logo-title {
  font-size: 1.125rem;
  margin-bottom: 0px;
  font-weight: 600;
}

.img-button:hover {
  cursor: pointer;
}

.main-container {
  padding: 25px !important;
}

.grid-window {
  background: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  /* border: 1px solid black; */
}

.grid-window .grid-window-title {
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
  height: 0px;
  background: linear-gradient(
    180deg,
    rgba(209, 213, 219, 1) 0%,
    rgba(243, 244, 246, 1) 100%
  );
  border-bottom: 1px solid rgba(209, 213, 219, 1);

  position: absolute;
  width: 100%;
  z-index: 1;

  visibility: hidden;

  /* transform: translateY(-1px); */
  top: -1px;
  opacity: 0;
  transition-timing-function: ease-out;
  transition: opacity 0.5s, height 0.4s, visibility 0.2s;
  /* once title bar is visible, stay visible for a bit more */
  transition-delay: 2s;
}

.grid-window-handle:hover + .grid-window-title,
/* .grid-window:hover > .grid-window-title, */
.grid-window-title:hover {
  visibility: visible;

  cursor: grab;
  cursor: url("./assets/images/hand-cursor.svg") 12 8, default;

  top: -1px;
  opacity: 1;
  height: 24px;
  /* clear delay, so the delay applies only when the title bar becomes visible, not before becoming visible */
  transition-delay: 0s;
}

.grid-window-handle {
  opacity: 0;
  position: absolute;
  left: calc(50% - 20px / 2);
  /* left: 2px; */
  /* right: 2px; */
  /* font-size: 14px; */
  top: -6px;
  z-index: 2;
  transition-timing-function: ease-out;
  transition: opacity 0.5s;
}

.grid-window:hover > .grid-window-handle {
  opacity: 1;
}

.grid-window .grid-window-content {
  padding: var(--grid-window-content-padding);
  position: relative;
  width: 100%;
  height: 100%;
}

/* react grid window resize hanlde - hide */
/* .react-resizable-handle-se {
  bottom: -2px !important;
  right: -2px !important;
  opacity: 0;
} */

.playback-button,
.playback-button-active {
  width: 80px;
  height: 23px;
  padding: 0px !important;
  font-size: 0.875rem !important;
  background: linear-gradient(
    180deg,
    rgba(243, 244, 246, 1) 0%,
    rgba(209, 213, 219, 1) 50%,
    rgba(243, 244, 246, 1) 100%
  );
  border-color: rgba(209, 213, 219, 1) !important;
  color: black !important;
}

.btn-group-playback.btn-group > .btn:hover {
  z-index: inherit !important;
  background: linear-gradient(
    180deg,
    rgba(243, 244, 246, 1) 0%,
    rgba(184, 186, 190, 1) 50%,
    rgba(243, 244, 246, 1) 100%
  );
}

.playback-button-active,
.btn-group-playback.btn-group > .btn:active {
  z-index: inherit !important;
  background: linear-gradient(
    180deg,
    rgba(243, 244, 246, 1) 0%,
    rgba(139, 142, 147, 1) 50%,
    rgba(243, 244, 246, 1) 100%
  );
}

.playback-button:focus,
.playback-button-active:focus {
  box-shadow: none !important;
}

/* table */

.table-container {
  overflow: auto;
  height: 100%;
}

/* remove bootstrap default line on first row that makes it look under the header */
.table > :not(:first-child) {
  border-top: 0px !important;
}

.table-thead-sticy:after,
.table-thead-sticy:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.table-thead-sticy:before {
  top: 0px;
  border-top: 2px solid currentColor;
  display: none;
}

.table-thead-sticy:after {
  bottom: 0px;
  border-bottom: 2px solid currentColor;
}

.table-thead-sticy {
  position: sticky;
  top: -1px;
  background-color: white;
}

/* react-virtualized */

@import "~react-virtualized/styles.css";

.GridRow {
  position: relative;
  display: flex;
  flex-direction: row;
}
.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.LeftSideGridContainer {
  flex: 0 0 75px;
  z-index: 10;
}

.LeftSideGrid {
  overflow: hidden !important;
}
.HeaderGrid {
  width: 100%;
  overflow: hidden !important;
}
.BodyGrid {
  width: 100%;
}

.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
}

.oddRow {
  background-color: rgba(0, 0, 0, 0.06);
}

.rowHover {
  background-color: rgba(0, 0, 0, 0.2);
}

.rowSelected {
  background-color: var(--bs-table-bg);
}

.cell,
.headerCell,
.leftCell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 0.5em;
}
.headerCell,
.leftCell {
  font-weight: bold;
}

.placeholder {
  display: inline-block;
  height: 16px;
  background-color: #ddd;
  cursor: default !important;
}

.placeholder:hover {
  cursor: default;
}

/* camera */

.camera-img-container {
  width: 100%;
  height: 100%;
}

.camera-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* pano cam */

.pnlm-container {
  padding: var(--grid-window-content-padding);
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  background: transparent !important;
}

/* hide author info */
.pnlm-panorama-info {
  display: none !important;
}

/* hide loading box each time image src is changed */
.pnlm-load-box {
  display: none !important;
}

/* hide click here to load button when image src is not set */
.pnlm-load-button {
  display: none !important;
}

/* remove tooltip with link to https://pannellum.org/ when right clicking */
.pnlm-about-msg {
  display: none !important;
}

/* hide error box when image src is not set */
/* .pnlm-error-msg {
  display: none !important;
} */

/* animation */

.background-masker {
  background-color: #fff;
  position: absolute;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: calc(100%);
  position: relative;
}

/* map */

.ol-map {
  padding: var(--grid-window-content-padding);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}

.loading-indicator.spinner-border {
  height: 20px !important;
  width: 20px !important;
}

.utrun.loading-indicator.spinner-border {
  height: 24px !important;
  width: 24px !important;
}

.map.loading-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.alert-danger {
  color: #fff !important;
  background-color: #e91e63 !important;
  border-color: transparent !important;
}

/* react grid layout */

/* disable text select during drag */
.react-draggable-transparent-selection {
  user-select: none;
}

/* navbar */

.hidden-arrow.dropdown-toggle:after {
  display: none;
}

/* .navbar {
  padding-bottom: 2px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 2px !important;
} */

/* .navbar-nav .nav-link .fa {
  color: var(--primary-color);
} */

.nav-link-image {
  opacity: 0.5;
}

.nav-link-image:hover {
  opacity: 0.8;
}

/* locators */
.locators-title {
  font-size: 10px;
  line-height: 13px;
  margin-top: -12px;
  font-weight: bold;
}

/* is the dropdown sign on the right */
.dropdown-toggle-split::after {
  /* Dropdown.tsx height of 28px might be ok with 0px but look ok with 0px margin also
  , use 0px for Dropdown.tsx height of 31px that was default */
  margin-bottom: 0px;
}

/* react-select__indicator react-select__dropdown-indicator css-tlfecz-indicatorContainer */

/* react-select__indicators css-vfgybp-IndicatorsContainer */

.react-select__indicators {
  display: none !important;
}

/* remove right part */
.react-select__control {
  border-right: 0px !important;
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

/* 
.react-select-container {
}

.react-select__control {
  font-size: 10px;
  min-height: 0px !important;
  height: 10px;
}

.react-select__input-container {
  height: 10px;
}

.react-select__value-container {

}

.react-select__menu {
  font-size: 10px !important;
} */

/* box */

.box-shadow {
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/* normal table */

.table-click-row {
  cursor: pointer;
}

/* form error message */

.input-form .error-message {
  background: #ed3c0d;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-weight: 600;
}

.input-form .submit-ok {
  background: #18d26e;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-weight: 600;
}

/* version number */
.version {
  position: fixed;
  top: 0px;
  right: 10px;
  z-index: 2;
  font-size: 10px;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.link {
  color: #444444;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: #444444;
  text-decoration: none;
}

.image-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 1px solid #999;
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  cursor: pointer;
  z-index: 2;
  transform: translateZ(9999px);
}

.image-button:hover {
  background-color: #f8f8f8;
}

.image-button-active {
  /* background-color: #3e78ce !important; */
  /* background-color: rgb(0, 120, 212) !important; */
  background-color: #fff !important;
  border: 0px solid #999;
  cursor: initial !important;
}

.image-button-link {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.55);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.image-button-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.image-button-link-text {
  cursor: pointer;
  color: initial;
  transition: initial;
}

.image-button-link-text:hover {
  color: initial;
}

/*--------------------------------------------------------------*/

/* Admin Sidebar Users Dropdown / Expander */

.dropdown-toggle {
  outline: 0;
}

.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
  background-color: var(--bs-tertiary-bg);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

[data-bs-theme="dark"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

/* .btn-toggle-nav div {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav div:hover,
.btn-toggle-nav div:focus {
  background-color: var(--bs-tertiary-bg);
} */

/*------/ Overlay /------*/

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(245, 245, 247, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

/*------/ BusyIndicator /------*/

.busy-indicator-spinner {
  width: 45px !important;
  height: 45px !important;
  font-size: 25px;
}

.busy-indicator-text {
  color: black;
  font: bold 25px Sans-Serif;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
}

/*------/ Dropdown /------*/

.dropdown {
  padding-left: 24px;
}

.dropdown li {
  list-style-type: none;
  margin: 10px;
}

.dropdown a {
  text-decoration: none;
  color: black;
}

.dropdown li a:hover,
.dropdown li span:hover {
  cursor: pointer;
  color: #0078d4;
}
