// $primary: #025ce3;
// $primary: #2c7be5;
// $primary: #e9edf2

$primary: #0078D4;

//$form-switch-color: $primary;

// $theme-colors: (
//   "primary": $primary,
// );

//$border-radius: 10rem;

@import "../../../../node_modules/bootstrap/scss/bootstrap";
